import React, { Component } from 'react';
import '../css/about.css';

class About extends Component {
  render(){
    return (
        <div className="wrap">
          <div className="about-me-image">
            <img src="/images/profile.jpg" alt="me"></img>
          </div>
          <div className="about-me-description">
            <p>Hungarian born, Australian raised photographer focusing on landscapes and glamour
           photography, but dabbling in fashion and urban themes from time to time.</p>
          </div>

        </div>
    )
  }
}
export default About;
