import React, { Component } from 'react';
import NavBar from './components/NavRouter.js'
import throttle from 'lodash.throttle';

import './App.css';

class App extends Component {



  resize = () => {
          this.forceUpdate()
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  render() {
    return (
       <NavBar />
    );
  }
}

export default App;
