import React, { Component } from 'react';
import '../css/footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faFlickr } from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons';


class Footer extends Component {
  render(){
    return (
<ul>
  <li>
    <a href="https://www.instagram.com/haag.i.g/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
  </li>
  <li>
    <a href="https://www.flickr.com/photos/haagistvan" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFlickr} /></a>
  </li>
  <li>
    <a href="mailto:ihaagfoto@gmail.com" ><FontAwesomeIcon icon={faEnvelope} /></a>
  </li>
  <li>
    <a href="/about" ><FontAwesomeIcon icon={faQuestionCircle} /></a>
  </li>

</ul>
    )
  }
}
export default Footer;
