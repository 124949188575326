import React, { Component } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



class Carousel extends Component {

  fillSet = () => {
    let set = [];
    let photoset = this.props.photoset;

    for(let i = 0; i< photoset.length;i++){

  //    c	medium 800
  //    b	large 1024
  //    h	large 1600
  //    k	large 2048

    //  let bUrl = 'https://farm'+ photoset[i]['farm'] +'.staticflickr.com/'+ photoset[i]['server'] +'/'+ photoset[i]['id'] +'_'+ photoset[i]['secret'] +'_b.jpg';

      const mWidth = photoset[i]['width_m'];
      const cWidth = photoset[i]['width_c'];
      const hWidth = photoset[i]['width_h'];
      const kWidth = photoset[i]['width_k'];
      const mUrl = photoset[i]['url_m'];
      const cUrl = photoset[i]['url_c'];
      const hUrl = photoset[i]['url_h'];
      const kUrl = photoset[i]['url_k'];
//srcSet={`${small} 300w, ${medium} 768w, ${large} 1280w`}

      set.push(

        <img className="slide-img" key={this.props.photoset[i]['id']}
           srcSet={`${mUrl} ${mWidth}w, ${cUrl} ${cWidth}w, ${hUrl} ${hWidth}w`}

           src={this.props.photoset[i]['url_c']}
           alt={this.props.photoset[i]['title']}
         />
      );
    }
    return set;
  }



  render() {
    let width = window.innerWidth;

    if (width<500) {
      return (
        <div className="container">
          {this.fillSet()}
        </div>
      );

    } else {

      const settings = {
        infinite: true,
        variableWidth: true,
        centerMode: true,
        adaptiveHeight: true
      }

      return (
        <Slider  {...settings}>
            {this.fillSet()}
         </Slider>
      );


    }
  }
}

export default Carousel;
