import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Link} from 'react-router-dom';
import Home from './Home.js';
import Footer from './Footer.js';
import Glamour from './Glamour.js';
import Landscape from './Landscape.js';
import Urban from './Urban.js';
import Fashion from './Fashion.js';
import About from './About.js';

function MenuLink({ label, to, activeOnlyWhenExact }){
  return(
    <Route
      path={to}
      exact={activeOnlyWhenExact}
      children={({match}) => (
        <li className={match ? "active" : ""}>
          <Link to={to}>{label}</Link>
        </li>
      )}
      />
  );
}

class NavRouter extends Component {

  constructor(props) {
      super(props)

      this.toggleClass= this.toggleClass.bind(this);
      this.state = {
        activeIndex: 0
      }
  }


  toggleClass(index, e) {

      this.setState({ activeIndex: index });
  };


  render() {
    return (
      <Router>
        <div>
          <div className="navbar">
          <span className="name-row">Haág István</span>
            <ul>
              <MenuLink activeOnlyWhenExact={true} to="/" label="Home"/>
              <MenuLink to="/glamour" label="Glamour"/>
              <MenuLink to="/landscape" label="Landscape"/>
              <MenuLink to="/urban" label="Urban"/>
              <MenuLink to="/fashion" label="Fashion"/>

            </ul>
          </div>

          <Route exact path="/" component={Home} />
          <Route path="/glamour" component={Glamour} />
          <Route path="/landscape" component={Landscape} />
          <Route path="/urban" component={Urban} />
          <Route path="/fashion" component={Fashion}/>
          <Route path="/about" component={About}/>

        </div>
        <div className="footer">
          <Footer/>
        </div>
      </Router>
    );
  }


}

export default NavRouter;
