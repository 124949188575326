import React, { Component } from 'react';
import Carousel from './Carousel.js';
import '../css/home.css';
import ScrollUpButton from "react-scroll-up-button";

const apiUrl = 'https://api.flickr.com/services/rest/?method=flickr.photosets.getPhotos&api_key=17ccb781bd25c7206668e0ce73a9d11d&photoset_id=72157707911869784&user_id=50764036%40N03&extras=views%2C+url_m%2C+url_k%2C+url_c%2C+url_h&format=json&nojsoncallback=1';

class Fashion extends Component {

  constructor(props){
    super(props);
    this.state = {
      error : null,
      isLoaded: false,
      photoset:[]
    };
  }
  componentDidMount() {
    fetch(apiUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            photoset: result.photoset.photo
          });
        },
        (error) => {
          this.setState(
            {
              isLoaded: true,
              error
            });
        }
      )
  }

  render(){
    const { error, isLoaded, photoset } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {

      return (
        <div className="full-width-carousel">

          <Carousel photoset={photoset}/>
          <ScrollUpButton ShowAtPosition={200} />
        </div>
      );
    }
  }
}
export default Fashion;
